import _ from 'lodash'
// import $ from 'jquery';
export default {
  isActive(overlay, key) {
    return overlay.s.current == key
  },
  formatArticles(overlay, app, data) {
    if (!data.menu_articles) return []
    const articles = data.menu_articles.slice(0, 3)

    return _.map(articles, (v) => {
      const date = `${v.published_at[3]}/${v.published_at[4]}/${v.published_at[5]}`

      return {
        title: v.title,
        date,
        image: app.v.showImage(v.image, '500x700'),
        url: v.url,
      }
    })
  },
}
