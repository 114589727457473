import _ from 'lodash'
// import $ from 'jquery';
import Vue from 'vue'

import Base from '../base'
import Event from './event'

export default class Default extends Base {
  init() {
    const main = this.b
    const settings = _.merge(this._default, {
      props: ['overlay', 'login'],
      mounted: function () {
        new Event(this, main)
      },
    })

    Vue.component('OverlayLogin', settings)
  }
}
