export default {
  active_options: '',
  selected_variant: -1,
  selected_quantity: 1,
  selected_options: [],
  selected_slide: 0,
  related: [],
  recommended: [],
  notify_email: '',
  message: '',
  error: false,
  current_tab: 0,
  related_products: false,
  recipient_name: '',
  recipient_email: '',
  recipient_error: '',
}
