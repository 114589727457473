import _ from 'lodash'
// import $ from 'jquery';

export default {
  addQty: (product) => {
    const quantity = product.s.selected_quantity
    product.s.selected_quantity = quantity + 1 > 10 ? 10 : quantity + 1
  },
  subQty: (product) => {
    const quantity = product.s.selected_quantity
    product.s.selected_quantity = quantity - 1 < 1 ? 1 : quantity - 1
  },
  selectOption: (product, option, data, ov) => {
    //Update selected options
    const selected_options = product.s.selected_options
    selected_options[option.type_index] = ov
    product.s.selected_options = [] // Force refresh
    product.s.selected_options = selected_options

    //Format selected options to match the way shopifies variant.options works
    const variant_options = []
    _.each(product.s.selected_options, (ov, idx) => {
      variant_options[idx] = ov
    })

    //Attempt to find variant that the options match
    const selected_variant = _.find(data.product.variants, (v) => {
      const clean_options = _.map(v.options, (vo) => {
        return vo
      })
      return _.isEqual(variant_options, clean_options)
    })

    //Update variant if one is found and not the same as old one
    if (selected_variant) {
      product.s.selected_variant = selected_variant
    }
  },
  toggleTab(product, idx) {
    product.s.current_tab = idx
  },
}
