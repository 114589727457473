<template>
  <transition name="fade">
    <div class="overlay-currencies__window" v-if="isActive">
      <div class="overlay-currencies__container">
        <div class="overlay-currencies__inner">
          <div class="overlay-currencies__heading">
            <h2 class="overlay-currencies__title">Update Currency</h2>
            <div class="overlay-currencies__close" @click="close">
              <svg-close />
            </div>
          </div>
          <div class="overlay-currencies__description">Please select from the list below.</div>
          <nav class="overlay-currencies__currencies">
            <button
              v-for="_currency in currencies"
              :key="_currency.iso_code"
              :class="`overlay-currencies__currency ${
                _currency.iso_code == currency.iso_code ? 'overlay-currencies__currency--active' : ''
              }`"
              @click.prevent="() => onClick(_currency.iso_code)"
            >
              {{ _currency.iso_code }}
            </button>
          </nav>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { LiquidCurrency } from '../../types/liquid'
import svgClose from '../svg-close.vue'

const props = defineProps<{
  overlay: any
}>()

const isActive = computed(() => props.overlay.s.current == 'currencies')
const currency = window._data.currency as LiquidCurrency
const currencies = (() => {
  const list = ['NZD', 'AUD', 'USD', 'CAD', 'GBP']
  const enabledCurrencies = window._data.currencies as LiquidCurrency[]
  return list.map((code) => enabledCurrencies.find((c) => c.iso_code == code))
})()

const close = () => window._app.bus.$emit('overlay/close')

const onClick = (code: string) => {
  window._app.bus.$emit('cart/update', { currency: code })
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}
</script>

<style lang="scss">
@import '~styles/base';
@import '~styles/frontend/typography/links';

.overlay-currencies {
  &__window {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    pointer-events: none;
    display: flex;
  }

  &__container {
    position: relative;
    margin: auto;
    background: #fff;
    border: 1px solid #010101;
    padding: 40px 40px 60px;
    max-width: calc(100vw - 40px);
    width: 400px;
    text-align: center;
    font-size: 16px;
    line-height: 1.3em;
    pointer-events: auto;
    @include cx(l1);
  }

  &__heading {
    @include cx('l3');
    text-align: center;
    margin-bottom: 30px;
  }

  &__description {
    text-align: center;
    padding-bottom: 2em;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
  }

  &__currencies {
    gap: 10px;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  }

  &__currency {
    @include cx-uc;
    @include cx('l1');
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &:hover,
    &--active {
      @include helper-underline();
    }
  }
}
</style>
