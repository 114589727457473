export default `
  <transition name="fade">
    <div class="overlay overlay-login" :class="{active:v.isActive(overlay)}">
      <div class="login-inner">
        <div class="inner">
          <div class="title">
            <h2> Account </h2>
            <div class="close"  @click="emit('overlay/close')"><svg-close /></div>
          </div>
          <slot>
          </slot>
        </div>
      </div>
    </div>
  </transition>
`
