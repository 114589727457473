export default {
  isActive(overlay) {
    return overlay.s.current == 'afterpay'
  },
  showClass(overlay) {
    return {
      active: overlay.s.current == 'afterpay',
    }
  },
}
