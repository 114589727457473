<template>
  <div class="cart">
    <div class="cart-inner">
      <!-- title -->
      <div class="title">
        <h2>Cart (<span v-text="showItemCount(d)"></span>)</h2>
      </div>

      <!-- items -->
      <CartItems v-if="hasItems(d)" :updating="updating" :items="d.cart.items" />

      <div v-else class="empty">
        <div class="empty-inner">
          <p>Nothing in your cart yet.</p>
        </div>
      </div>

      <div class="controls">
        <div class="controls-inner">
          <div class="total" v-if="hasItems(d)">
            TOTAL:
            <span v-html="displayMoney(d.cart.total_price)"></span>
          </div>
          <div class="next" v-if="hasItems(d)">
            <a href="/checkout">Checkout</a>
          </div>
          <div class="next" v-if="!hasItems(d)">
            <a href="/">Keep Shopping</a>
          </div>
        </div>
      </div>

      <CartNotes :cart="d.cart" :updating="updating" />
    </div>
  </div>
</template>

<script>
import { displayMoney } from '../lib/money'
import CartItems from './cart/cart-items.vue'
import CartNotes from './cart/cart-notes.vue'

function bus() {
  return window._app.bus
}

export default {
  props: { app: Object, d: Object },
  data() {
    return {
      current_action: '',
      updating: false,
    }
  },
  mounted() {
    // new Event(this, main);
    this.$nextTick(() => {
      bus().$on('cart/item/updating', (d) => {
        this.updating = true
      })

      bus().$on('cart/item/updated', (d) => {
        this.updating = false
      })
    })
  },
  methods: {
    displayMoney,
    bus() {
      return window._app.bus
    },
    isActive(overlay) {
      return overlay.s.current == 'cart'
    },
    hasItems: (data) => {
      return data.cart.items && data.cart.items.length
    },
    showItemCount: (data) => {
      return data.cart.item_count
    },
  },
  components: {
    CartItems,
    CartNotes,
  },
}
</script>
