import Base from '../base'

export default class Default extends Base {
  init() {
    this.bus.$on('cart/item/add', (d) => this.add(d))
    this.bus.$on('cart/item/remove', (d) => this.remove(d))
    this.bus.$on('cart/item/update', (d) => this.updateItem(d))
    this.bus.$on('cart/update', (d) => this.update(d))
    this.bus.$on('cart/note', (d) => this.updateNote(d))

    this.bus.$on('cart/item/increment', (d) => this.increment(d))
    this.bus.$on('cart/item/decrement', (d) => this.decrement(d))
  }

  add(d) {
    const id = d.id
    const quantity = typeof d.quantity != 'undefined' ? d.quantity : 1
    const properties = typeof d.properties != 'undefined' ? d.properties : {}

    this.bus.$emit('cart/item/adding')
    this.u.shopify.cart.add({
      data: {
        quantity: quantity,
        id: id,
        properties,
      },
      callback: (_) => {
        this.refresh('cart/item/added')

        if (this.b.vm.window.s.resize.width.current > 800) {
          this.bus.$emit('overlay/menu', { overlay: 'menu-cart', ignore_hover: true })
        } else {
          this.bus.$emit('overlay/menu', { overlay: 'cart' })
        }
      },
    })
  }

  remove(d) {
    const id = d.id
    this.bus.$emit('cart/item/removing')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: 0,
        },
      },
      callback: () => {
        this.refresh('cart/item/removed')
      },
    })
  }

  increment({ id, quantity }) {
    this.updateItem({
      id,
      quantity: quantity + 1,
    })
  }

  decrement({ id, quantity }) {
    this.updateItem({
      id,
      quantity: quantity - 1,
    })
  }

  updateItem(d) {
    const id = d.id
    const qty = d.quantity
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: qty,
        },
      },
      callback: () => {
        this.refresh('cart/item/updated')
      },
    })
  }

  update(d) {
    this.bus.$emit('cart/updating')
    this.u.shopify.cart.update({
      data: d,
      callback: () => {
        this.refresh('cart/updated')
      },
    })
  }

  updateAttributes(attributes) {
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        attributes: attributes,
      },
      callback: () => {
        this.refresh('cart/item/updated')
      },
    })
  }

  updateNote(note) {
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        note,
      },
      callback: () => {
        this.refresh('cart/item/updated')
      },
    })
  }

  refresh(path) {
    //Refresh the cart contents
    this.u.shopify.cart.get({
      callback: (d) => {
        this.d.cart = d
        this.bus.$emit(path)
      },
    })
  }
}
