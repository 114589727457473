<template>
  <video loop muted playsinline autoplay :poster="media.preview_image" class="shopify-video" v-if="src">
    <source :src="src" type="video/mp4" />
  </video>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { LiquidMedia } from '../../types/liquid';

const props = defineProps<{
  media: LiquidMedia
}>()

const src = computed(() => props.media.sources?.find((source) => source.url.indexOf('.mp4') > -1)?.url)
</script>

<style lang="scss">
.shopify-video {
  height:100%;
  width: 100%;
  display: flex;
  object-fit: cover;
}
</style>
