import _ from 'lodash'
// import $ from 'jquery';
// import Vue from 'vue';

export default {
  imageStyle(c) {
    const style = {}
    const selected = _.find(c.s.category_images, { category: c.s.selected_category })

    if (selected) {
      style['background-image'] = `url('${selected.image}')`
    }

    return style
  },

  formatMakerImages(app, block) {
    if (!block) return

    const maker = block.maker
    const resize = '800x800'
    const showImage = app.v.showImage
    const maker_image = maker.collection.image.src
    let images = []

    if (maker_image) images.push(showImage(maker_image, resize))

    if (maker.products.length > 0) {
      images = images.concat(
        _.map(maker.products, (v) => {
          const url = v.featured_image?.src || v.featured_image
          if (url) return showImage(url, resize)
        })
      )
    }

    images = _.compact(images)

    return images.slice(0, 5)
  },
}
