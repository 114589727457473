<template>
  <form class="form" method="get" @submit.prevent="submit($event)">
    <div class="input">
      <input
        v-model="email"
        class="text"
        type="text"
        :placeholder="placeholderMessage"
        aria-label="Email"
        required
      />

      <button class="submit" aria-label="Submit">Join</button>
    </div>

    <div v-if="message !== ''" class="message" v-html="message" />
  </form>
</template>

<script>
// const Klaviyo = require('klaviyo-subscribe')
import {subscribe} from "klaviyo-subscribe"

export default {
  props: {
    successMessage: {
      type: String,
      default: 'Thanks for signing up!',
    },
    failMessage: {
      type: String,
      default: 'Please enter a valid email address',
    },
    placeholderMessage: {
      type: String,
      default: 'Email Address',
    },
    messageTimeout: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listId: 'WfuX8n',
      active: true,
      email: '',
      name: '',
      message: '',
    }
  },
  methods: {
    activate() {
      this.active = true
      setTimeout(() => {
        const inputs = this.$el.getElementsByTagName('input')
        if (inputs.length > 0) inputs[0].focus()
      }, 1000)
    },
    async submit() {
      const response = await subscribe(this.listId, this.email, {})

      if (response.success) {
        this.message = this.successMessage
        this.email = ''
      } else {
        this.message = this.failMessage
      }

      if (this.messageTimeout > 0) {
        setTimeout(() => {
          if (this.message != '') {
            this.message = ''
          }
        }, this.messageTimeout)
      }
    },
  },
}
</script>
