/* eslint-disable @typescript-eslint/no-explicit-any */
import Options from './view/options'
import Buy from './view/buy'
import _ from 'lodash'
import { displayMoney } from '../../lib/money'
// import $ from 'jquery';

const Product = {
  showPrice: (product: any) => {
    const v = product.s.selected_variant

    let message = ''
    const price = displayMoney(v.price)

    const is_low = !!(
      v.inventory_management == 'shopify' &&
      v.inventory_policy == 'deny' &&
      v.inventory_quantity <= 1 &&
      v.inventory_quantity > 0
    )

    if (is_low) message = 'Last One'

    if (v.compare_at_price > v.price) {
      const compare_price = displayMoney(v.compare_at_price)
      message = `Was ${compare_price}`
    }
    if (!v.available) message = 'Sold Out'

    //Not available
    const tags = product.b.d.product.tags
    if (tags.indexOf('purchase-instore') > -1 || tags.indexOf('purchase-special') > -1) {
      message = ''
    }

    return message ? `${price} <span class="message">(${message})</span>` : price
  },
  showTabIcon: (product, idx) => {
    return idx == product.s.current_tab ? '−' : '+'
  },
  showNotify: (data, p) => {
    const product = data.product
    const variant = p.s.selected_variant
    const available = variant.available
    const is_notifiable = _.includes(product.tags, 'notifiable')

    return is_notifiable && !available ? true : false
  },
  isNotified: () => {
    const get = []
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
      get[name] = value
    })

    return !!get['contact_posted']
  },
  showContentClass: (s) => {},
  formatSlideshow(product, images) {
    return _.chain(images)
      .map((img, idx) => {
        return {
          alt: img.alt,
          url: img.src,
          width: img.width,
          height: img.height,
          class: product.s.selected_slide == idx ? 'active' : '',
        }
      })
      .value()
  },
  formatRelated: (product_data, state: any = false) => {
    const related = _.map(product_data, (p) => {
      return _.merge(p, {
        mood: p.images.length > 1 ? p.images[1].src : p.images[0].src,
      })
    })

    if (state) {
      if (state.related_products) return state.related_products
      state.related_products = _.shuffle(related).slice(0, 12)
      return state.related_products
    } else {
      return related.slice(0, 12)
    }
  },
  showAfterpay(product) {
    const count = 4
    const v = product.s.selected_variant
    const price = displayMoney(v.price / count)

    return `Or ${count} payments from ${price} <br>
    <span class="afterpay-logo" >
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 952.13 552.32"><path id="Fill-16" d="M498.56,235.87l98.76-57.16c-10.92-19.16-8.26-14.55-18.2-32.62-10.54-19.15-6.57-26.67,15.19-26.83q95-.66,190.1-.17c18.82.11,23.36,8.16,13.92,24.76q-47,82.68-95,164.78c-10.21,17.48-19.14,17.44-29.81.34-11-17.57-8-13.23-19.77-33.15L555.31,333c1.94,5.07,4.42,8.32,6.45,11.85,24.38,42.44,35.27,62.71,60.47,104.66,29.85,49.72,92.44,52.71,128,6.76A144.31,144.31,0,0,0,761,440.13q89.7-155.36,179.07-310.9c6-10.51,11.46-22,14.07-33.71C964.56,48.81,929.9,4.64,881.2,4.38c-128.14-.68-256.3-.78-384.44.35-58.37.51-93.45,59-67.81,110.31,8.36,16.72,18.38,32.61,27.77,48.81,17.67,30.5,22.34,38.52,41.84,72" transform="translate(-3.87 -3.87)" style="fill:#306e9a;fill-rule:evenodd"/><path id="Fill-18" d="M213.64,555.86c.06-38.44.16-113.68.16-113.68S198,442,176.75,442c-21.68,0-26.15-7.66-15.45-26.4q46.77-81.87,94.56-163.17c9.47-16.1,17.85-17.51,28.19.38,31.45,54.48,63,108.89,94,163.6,9.91,17.47,5.42,25.11-14.57,25.75-20.63,0-38.28,0-38.28,0v114l133.32,0c57.52-.85,91.27-53.05,69.55-106.44a143.17,143.17,0,0,0-8.45-17.29Q431,278.1,342.11,123.93c-6-10.4-13.15-20.78-21.9-28.86-34.89-32.22-90-24.49-114.54,17.17Q109,276.64,14.44,442.24c-28.64,50.34,4,109.55,60.86,113.14,18.5,1.16,97.52.48,138.34.48" transform="translate(-3.87 -3.87)" style="fill:#1f4066;fill-rule:evenodd"/></svg> 
    </span> 
    
    <em>What's this?</em>`
  },
}

export default _.merge(Product, Buy, Options)
