<template>
  <div class="component-reveal" :class="showClass">
    <slot />
  </div>
</template>

<script>
import BrowserMixin from '../mixins/browser'

export default {
  mixins: [BrowserMixin],
  data() {
    return {
      offsetBottom: 180,
      active: false,
    }
  },
  computed: {
    showClass() {
      return { 'component-reveal--active': this.active }
    },
  },
  watch: {
    scrollTop: {
      handler: 'checkIfActive',
      immediate: true,
    },
    resizeHeight: {
      handler: 'checkIfActive',
      immediate: true,
    },
  },
  methods: {
    checkIfActive() {
      const scrollTop = this.scrollTop
      const windowHeight = this.resizeHeight

      if (!this.$el) return

      const offsetTop = this.$el.offsetTop

      if (offsetTop < scrollTop + windowHeight - this.offsetBottom) {
        this.active = true
      } else {
        this.active = false
      }
    },
  },
}
</script>

<style lang="scss">
.component-reveal {
  transition: opacity 1s ease-out, transform 1s ease-out;
  opacity: 0;
  transform: translateY(5px);
  &--active {
    transform: none;
    opacity: 1;
  }
}
</style>
