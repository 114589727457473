import _ from 'lodash'
// import $ from 'jquery';

export default {
  hasOptions: (data) => {
    return data.product.variants.length > 1
  },
  formatOptions: (data) => {
    const options = []

    _.each(data.product.options, (ot, ot_idx) => {
      options[ot_idx] = {
        type: ot,
        type_index: ot_idx,
      }

      options[ot_idx].values = _.chain(data.product.variants)
        .map((variant) => {
          const variants = data.product.variants
          //Format sizes as numbers
          const title = variant.options[ot_idx]
          const available =
            variants
              .filter((v) => v.available)
              .filter((v) => Object.keys(v.options).some((key) => v.options[key] === title)).length > 0

          return {
            title,
            available,
          }
        })
        .uniqWith(_.isEqual)
        .value()
    })

    return options
  },
  showOptionClass: (product, option, option_value) => {
    const ot_index = option.type_index
    const selected_options = product.s.selected_options

    return `${
      selected_options[ot_index] && selected_options[ot_index] === option_value.title && 'active'
    } ${!option_value.available && 'disabled'}`
  },
}
