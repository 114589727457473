export default class Default {
  constructor(c, main) {
    this.c = c
    this.bus = main.bus
    this.ls = main.ls

    this.bus.$on('banner/open', (d) => this.openBanner(d))
    this.bus.$on('banner/close', (d) => this.closeBanner(d))

    if (!window.localStorage.getItem('BANNER_CLOSED')) {
      this.bus.$emit('banner/open')
    }
  }

  openBanner() {
    this.c.s.active = true
  }

  closeBanner(d) {
    window.localStorage.setItem('BANNER_CLOSED', true)
    this.c.s.active = false
  }
}
