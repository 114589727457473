<template>
  <ShopifyImage v-if="isImage" :src="`${media.preview_image}`" :ratio="ratio" :alt="alt" />
  <ShopifyVideo v-else-if="isVideo" :media="media" />
</template>

<script lang="ts" setup>
import ShopifyImage from './ShopifyImage.vue'
import ShopifyVideo from './ShopifyVideo.vue'
import { LiquidMedia } from '../../types/liquid'

const props = defineProps<{
  media: LiquidMedia
  ratio: string
}>()

const isImage = props.media?.media_type == 'image'
const isVideo = props.media?.media_type == 'video'

const alt = (isImage && props.media?.alt) || ''
</script>

<style lang="scss"></style>
