<template>
  <div v-if="list.length > 0" class="breadcrumbs">
    <nav>
      <ul>
        <li v-for="(v, k) in list" :key="`crumb_${k}`">
          <a :href="v[1]" v-text="v[0]" />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: ['tags', 'collections'],
  data: function () {
    return {
      list: [['Shop', '/collections/all']],
    }
  },
  mounted: function () {
    // Looks for tag in format breadcrumb-{handle}-{handle}
    const tag_index = this.tags.findIndex((v) => {
      return new RegExp('^breadcrumb').test(v)
    })
    if (tag_index > -1) {
      const c_handles = this.tags[tag_index].split('-')
      c_handles.shift()

      _.each(c_handles, (v) => {
        const c = _.find(this.collections, { handle: v })
        if (c) {
          this.list.push([c.title, `/collections/${c.handle}`])
        }
      })
    }
  },
}
</script>
