<template>
  <div class="items">
    <div v-for="(li, k) in items" :key="`item_${k}`" class="item" :class="{ updating }">
      <div class="item-inner">
        <div class="remove">
          <span class="a" @click="bus.$emit('cart/item/remove', { id: li.id })"><svg-close /></span>
        </div>
        <div class="image">
          <div class="image-inner">
            <a :href="li.url"
              ><img
                :src="showImage(li.featured_image.url, '450x600_crop_center')"
                :alt="li.product_title"
            /></a>
          </div>
        </div>
        <div class="title">
          <a :href="li.url">
            <span v-html="li.vendor" /><br />
            <span v-html="li.product_title" />
            <span v-if="li.variant_title" v-html="', ' + li.variant_title" />
          </a>
        </div>
        <div class="details">
          <div class="price" v-html="showItemPrice(li)" />
          <div class="selection">
            <div>
              QTY:
              <span
                class="select"
                @click="
                  bus.$emit('cart/item/decrement', {
                    id: li.id,
                    quantity: li.quantity,
                  })
                "
                >–</span
              >
              <span v-text="li.quantity" />
              <span
                class="select"
                @click="
                  bus.$emit('cart/item/increment', {
                    id: li.id,
                    quantity: li.quantity,
                  })
                "
                >+</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { displayMoney } from '../../lib/money'

export default {
  props: { updating: Boolean, items: Array },
  data() {
    return {
      current_action: '',
    }
  },
  computed: {
    bus() {
      return window._app.bus
    },
  },
  methods: {
    showImage: (url, format = '600x800_crop_center') => {
      if (!url) return
      const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1

      return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
    },
    showItemPrice: (li) => {
      return displayMoney(li.price)
    },
  },
}
</script>
