export default {
  showBuyText: (product, cart) => {
    const variant = product.s.selected_variant
    const cart_state = cart.s

    //Default
    let string = 'Add to Cart'

    //Available state
    // if (app.s.has_mouse == false) {
    if (variant != -1 && !variant.available) {
      string = 'Sold Out'
    }
    // }

    //Adding to cart
    if (cart_state.current_action == 'adding') string = 'Adding to Cart'
    if (cart_state.current_action == 'added') string = 'Added!'

    //Not available
    const tags = product.b.d.product.tags
    if (tags.indexOf('purchase-instore') > -1) {
      string = 'In Store'
    }
    if (tags.indexOf('purchase-special') > -1) {
      string = 'Special Order'
    }

    return string
  },
  showBuyMessage: (product) => {
    const variant = product.s.selected_variant
    const quantity = variant.inventory_quantity
    const management = variant.inventory_management
    const policy = variant.inventory_policy
    let message = product.s.message

    //On sale
    // if (variant != -1) {
    // if (variant.compare_at_price > variant.price) {
    //   message = "On Sale"
    // }
    // }

    //If shopify is handling inventory
    if (management == 'shopify') {
      //Preorders allowed and no stock
      // if(policy == 'continue' && quantity < 1) {
      //   message = data.settings.product_preorder;
      // }
      // if(policy == 'deny' && quantity < 1) {
      //   message = data.settings.product_notify;
      // }

      if (quantity < 3 && quantity > 0) {
        // message = `This selection is low in stock!`;
      }
    }

    //Unavailable
    // if (state.app.has_mouse == true) {
    if (variant != -1) {
      if (!variant.available) {
        // message = "Sold Out";
      }
    }
    // }

    //Not available
    const tags = product.b.d.product.tags
    if (tags.indexOf('purchase-instore') > -1) {
      message =
        'This product is not available for online purchase, please contact the store to place your order on <A href="mailto:shop@tessuti.co.nz" target="_blank">shop@tessuti.co.nz</a> or 09 3764802'
    }
    if (tags.indexOf('purchase-special') > -1) {
      message =
        'Special Order  - this item is available to order with a 50% deposit. Please contact the store for approximate lead times and deposit information on <A href="mailto:shop@tessuti.co.nz" target="_blank">shop@tessuti.co.nz</a> or 09 3764802'
    }

    return message
  },
  showBuyClass: (product) => {
    let disabled = !product.v.getBuyAvailability(product)

    const tags = product.b.d.product.tags
    if (tags.indexOf('purchase-instore') > -1 || tags.indexOf('purchase-special') > -1) {
      disabled = true
    }

    return { disabled }
  },
  getBuyAvailability: (product) => {
    const variant = product.s.selected_variant
    const available = variant.available

    return available
  },
}
