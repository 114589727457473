export default `
  <transition name="fade">
    <div class="overlay-banner" v-if="v.isActive(s.active)">
      <div class="overlay-banner__inner">
        <span v-text="text"></span>
        <div class="overlay-banner__close" @click="emit('banner/close')"><svg-close /></div>
      </div>
    </div>
  </transition>
`
