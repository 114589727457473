import _ from 'lodash'
// import $ from 'jquery';
// import Vue from 'vue';

export default {
  showClass(window, page_class) {
    const ws = window.s
    const ot = ws.scroll.top.current
    const bh = ws.resize.height.current
    const classes = {}

    return classes
  },
  isToggleActive(app, key) {
    return app.s.toggle == key
  },
  isHeaderActive(w, key) {
    const style = getComputedStyle(document.body)
    const breakpoint = style.getPropertyValue('--app-header-breakpoint')
    const width = w.s.resize.width.current

    if (key == 'desktop') {
      return width > breakpoint
    } else {
      return width <= breakpoint
    }
  },
  showToggleClass(app, key) {
    return { active: app.v.isToggleActive(app, key) }
  },
  showImage: (url, format = '600x800_crop_center') => {
    if (!url) return
    const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1

    return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
  },
  showLinkClass: (d, type, title, handle, current_template) => {
    let active = false

    if (type == 'catalog_link' && title == 'Shop') {
      if (current_template.indexOf('product') > -1) {
        active = true
      }
    }

    if (type == 'collection_link' && d.product) {
      if (handle == d.product.collection_handle) {
        active = true
      }
    }

    return {
      active: active,
    }
  },
  showHeaderClass: (d, app) => {
    if (d.window.s.scroll.top.current > 50) {
      return app.s.background_alt ? 'active background_alt' : 'active'
    }
  },
  showBackgroundAltClass: (d) => {
    // So far, only the footer needs this
    const templates = ['product ', 'article article-journal']

    return templates.indexOf(d.template) !== -1 ? 'background-alt' : ''
  },

  formatMakerImages(app, collection, products) {
    const resize = '800x800'
    const showImage = app.v.showImage
    const maker_image = collection.image
    let images = []

    if (maker_image) images.push(showImage(maker_image, resize))

    if (products.length > 0) {
      images = images.concat(
        _.map(products, (v) => {
          const url = v.featured_image.src
          if (url) return showImage(url, resize)
        })
      )
    }

    return _.compact(images)
  },
}
