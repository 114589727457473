export function resizeImage(url: string, format: string) {
  const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
  return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
}

type ShopifyImageOptions = {
  modifiers: {
    width: number
    ratio: string
  }
}

function constructShopifyImageUrl({
  src,
  width,
  height,
  cropping = '_crop_center',
}: // quality,
{
  src: string
  width: number
  height?: number
  cropping?: '_crop_center'
  // quality?: string;
}) {
  const url = `${src}?`
  const dotIndex = url.lastIndexOf('.')
  const format = width ? `_${Math.ceil(width)}x${height && `${Math.ceil(height)}${cropping}`}` : ''

  return [url.slice(0, dotIndex), `${format}`, url.slice(dotIndex)].join('')
}

export function ratioAsNumber(ratio: string): number {
  const [width, height] = ratio.split('x').map((v) => parseFloat(v))
  return width / height
}

export const getShopifyImage = (src: string, { modifiers }: ShopifyImageOptions) => {
  const finalWidth = Math.min(modifiers.width, 1600)
  const ratio = ratioAsNumber(modifiers.ratio)
  const height = Math.ceil(finalWidth / ratio)

  return {
    url: constructShopifyImageUrl({
      src,
      width: finalWidth,
      height,
      // quality,
    }),
  }
}
