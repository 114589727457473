export default {
  toggle(app, key, toggle = -1) {
    if (toggle == -1) {
      //Flip whatever it currently is
      app.s.toggle = app.s.toggle == key ? '' : key
    } else {
      //Set based on toggle
      app.s.toggle = toggle ? key : ''
    }

    //emit
    app.bus.$emit('app/toggle', {
      key: key,
      toggle: app.s.toggle,
    })
  },
}
