<template>
  <div class="cart-notes" :class="{ updating }">
    <div class="inner">
      <label class="label">
        <input v-model="active" type="checkbox" class="checkbox" :checked="active" />
        <span class="label-text">Add an order note</span>
      </label>
      <form v-if="active" class="form" @submit.prevent="onSubmit">
        <textarea v-model="note" class="input" placeholder="Order Notes" />
        <button class="button">Save</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object,
    },
    updating: Boolean,
  },
  data() {
    return {
      active: false,
      note: '',
    }
  },
  mounted() {
    this.note = this.cart.note
    if (this.note != '') this.active = true
  },
  methods: {
    onSubmit() {
      const { bus } = window._app
      bus.$emit('cart/note', this.note)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~styles/base.scss';
@import '~styles/frontend/forms/button.scss';
@import '~styles/frontend/forms/radio.scss';
@import '~styles/frontend/forms/input.scss';

.cart-notes {
  border-top: 1px solid $dark;
  margin-top: 20px;

  &.updating {
    opacity: 0.5;
    pointer-events: none;
  }

  .inner {
    padding: 20px;
    width: 100%;
    max-width: 320px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }

  .label {
    margin-right: 20px;
    display: flex;
  }

  .label-text {
    margin-right: auto;
  }

  .checkbox {
    @include form-checkbox;
    display: inline-flex;
    margin-right: 1ch;
    margin-left: auto;
  }

  .input {
    margin-top: 1em;
    margin-bottom: 1em;
    @include form-input;
    @include serif;
    border-radius: 5px;
    appearance: none;
  }

  .button {
    @include form-button;
  }
}
</style>
