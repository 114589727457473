<template>
  <div v-if="getActive()" class="overlay overlay-gallery">
    <div class="controls">
      <div class="close" @click="closeOverlay()" />
    </div>
    <div class="inner">
      <div v-for="(slide, slide_idx) in slides" :key="`slide_${slide_idx}`" class="slide">
        <img :src="resizeImage(slide.url, dimensions)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { resizeImage } from '../../lib/image'

export default {
  props: ['slides', 'overlay', 'dimensions'],
  methods: {
    resizeImage,
    closeOverlay() {
      this.$emit('update:overlay', '')
    },
    getActive: function () {
      return this.overlay == 'gallery'
    },
  },
}
</script>
