export default /*html*/ `
  <transition name="fade">
    <div class="overlay overlay-afterpay" :class="{active:v.isActive(overlay)}">
      <div class="overlay-inner">
        <div class="close" @click="emit('overlay/close')"><svg-close /></div>
        <img :src="afterpayImageSrc" />
      </div>
    </div>
  </transition>
`
