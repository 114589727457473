const BrowserMixin = {
  data() {
    return {
      scrollTop: 0,
      resizeHeight: 0,
      resizeWidth: 0,
      pageHeight: 0,
    }
  },
  methods: {
    updateWindowScroll() {
      const doc = document.documentElement
      this.scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      this.onWindowUpdate()
    },
    updateWindowSize() {
      const body = document.body
      const html = document.documentElement

      this.resizeHeight = Math.max(html.clientHeight, window.innerHeight || 0)

      this.resizeWidth = Math.min(html.clientWidth, window.innerWidth || 0)

      this.pageHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )
      this.onWindowUpdate()
    },
    onWindowUpdate() {
      // console.log('Window Update', this.$vnode.tag)
    },
  },
  created() {
    window.addEventListener('scroll', this.updateWindowScroll)
    window.addEventListener('resize', this.updateWindowSize)

    this.$nextTick(() => {
      this.updateWindowSize()
      this.updateWindowScroll()
    })
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateWindowScroll)
    window.removeEventListener('resize', this.updateWindowSize)
  },
}

export default BrowserMixin
