import image from '../../images/afterpay.jpg'

import _ from 'lodash'
import Vue from 'vue'

import Base from '../base'
import Event from './event'

export default class Default extends Base {
  data() {
    //Returns eg e:e,v:v,m:m
    return {
      s: this.f.s,
      v: this.f.v,
      m: this.f.m,
      bus: this.bus,
      d: this.d,
      // afterpayImageSrc: `${this.d.asset_url}afterpay.static.jpg`,
      afterpayImageSrc: image,
    }
  }

  init() {
    Vue.component(
      'OverlayAfterpay',
      _.merge(this._default, {
        props: ['overlay'],
        mounted() {
          new Event(this)
        },
      })
    )
  }
}
