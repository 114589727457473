import _ from 'lodash'
import Base from '../base'

export default class Default extends Base {
  init() {
    if (this.d.template.indexOf('product') == -1) return

    this.bus.$on('vue/loaded', () => {
      this.initVariant()
    })

    // this.b.bus.$on('vue/loaded', (d) => { this.initRelated(); });
    // this.b.bus.$on('vue/loaded', (d) => { this.initRecommended(); });

    this.b.bus.$on('product/buy', (d) => {
      this.buy(d)
    })
    this.b.bus.$on('cart/item/adding', () => {
      this.cartItemAdding()
    })
    this.b.bus.$on('cart/item/added', () => {
      this.cartItemAdded()
    })
  }

  initVariant() {
    //Select first variant in stock
    let variant = this.d.product.variants.filter((v) => v.available)[0]

    //Failing that, just grab the first one
    if (!variant) variant = this.d.product.variants[0]

    // if(this.d.product.variants.length == 1){
    //Set variant
    this.s.selected_variant = _.clone(variant)
    //Set options
    this.s.selected_options = _.clone(variant.options)
    // }
  }

  buy(d) {
    const variant_id = this.s.selected_variant.id
    const quantity = this.s.selected_quantity

    let valid = true
    if (typeof variant_id == 'undefined') valid = false

    if (valid) {
      this.s.error = false
      if (this.d.product['gift_card?']) {
        if (!this.s.recipient_email) {
          this.s.recipient_error = 'Please enter a valid email address'
          return
        }

        const properties = {
          'Recipient name': this.s.recipient_name,
          'Recipient email': this.s.recipient_email,
          __shopify_send_gift_card_to_recipient: true,
        }

        this.bus.$emit('cart/item/adding')
        this.bus.$emit('cart/item/add', { id: variant_id, quantity, properties })
      } else {
        this.bus.$emit('cart/item/adding')
        this.bus.$emit('cart/item/add', { id: variant_id, quantity: quantity })
      }
    } else {
      this.s.error = 'Please make a selection'
    }
  }

  cartItemAdding() {
    const cart = this.b.features.cart
    cart.s.current_action = 'adding'
  }

  cartItemAdded() {
    const cart = this.b.features.cart
    cart.s.current_action = 'added'
    setTimeout(() => {
      cart.s.current_action = ''
    }, 2000)
  }

  initRelated() {
    //Search for related products based on tag
    const tags = this.d.product.tags
    const tag = _.filter(tags, (t) => {
      return t.indexOf('related-') !== -1
    })[0]

    if (!tag) return //No point searching without tag
    const handle = tag.replace('related-', '')

    this.u.shopify.products.collection({
      handle: handle,
      callback: (products) => {
        //Filter out the current product, because we don't need it
        const related = []
        if (products.length >= 1) {
          //Remove current product from list
          this.s.related = this.formatRelated(products)
        }
      },
    })
  }

  initRecommended() {
    //Search for related products based on tag
    const tags = this.d.product.tags
    const tag = _.filter(tags, (t) => {
      return t.indexOf('recommended-') !== -1
    })[0]

    if (!tag) return //No point searching without tag
    const handle = tag.replace('recommended-', '')

    this.u.shopify.products.collection({
      handle: handle,
      callback: (products) => {
        //Filter out the current product, because we don't need it
        const related = []
        if (products.length >= 1) {
          //Remove current product from list
          this.s.recommended = this.formatRelated(products)
        }
      },
    })
  }

  formatRelated(products, include_self = false) {
    return _.chain(products)
      .filter((p) => {
        return include_self ? true : p.id !== this.d.product.id
      })
      .shuffle()
      .slice(0, 3)
      .value()
  }
}
