import Base from '../base'

export default class Default extends Base {
  init() {
    this.bus.$on('overlay/open', (d) => this.toggle(d, true))
    this.bus.$on('overlay/menu', (d) => this.menu(d))
    this.bus.$on('overlay/close', (d) => this.toggle(d, false))
    this.bus.$on('overlay/hover', (d) => this.onHover(d))
    this.bus.$on('overlay/toggle', (d) => this.toggle(d))

    this.eventKeys()
  }

  toggle(d, toggle = -1) {
    const key = d && d.overlay ? d.overlay : ''

    if (toggle == -1) {
      //Flip whatever it currently is
      this.s.current = this.s.current == key ? '' : key
    } else {
      //Set based on toggle
      this.s.current = toggle ? key : ''
    }

    if (this.s.current == '') this.s.ignore_hover
  }

  menu(d) {
    this.s.ignore_hover = !!d.ignore_hover
    this.s.menuHoverBuffer = true

    if (this.s.current.indexOf('menu-') !== -1 && d.overlay !== '') {
      this.s.current = d.overlay
    }

    setTimeout(() => {
      if (this.s.menuHoverBuffer && d.overlay) this.s.current = d.overlay
    }, 0)
  }

  onHover() {
    if (this.s.current && this.s.current.startsWith('menu-') && this.s.ignore_hover == false) {
      this.s.menuHoverBuffer = false
      // console.log('overlay buffer',this.s.menuHoverBuffer);
      this.bus.$emit('overlay/close')
    }
  }

  eventKeys() {
    //Close overlay if someone hits escape
    document.addEventListener('keydown', (evt) => {
      evt = evt || window.event
      let isEscape = false
      if ('key' in evt) {
        isEscape = evt.key == 'Escape' || evt.key == 'Esc'
      } else {
        isEscape = evt.keyCode == 27
      }
      if (isEscape) {
        this.bus.$emit('overlay/close')
      }
    })
  }
}
