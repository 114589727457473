// import _ from 'lodash';
import $ from 'jquery'
// import Vue from 'vue';

import Base from '../base'

export default class Default extends Base {
  init() {
    // if(this.d.template.indexOf('account-address') == -1) return;
    // this.bus.$on('vue/loaded', (d) => {this.states();});
    this.domData = this.getDomData() || []

    this.bus.$on('window/resize', () => this.updateDomData())
    this.bus.$on('window/resize', (d) => this.updateJournalPosts(d))
    this.bus.$on('window/scroll', () => this.setBackground())

    this.updateJournalPosts()

    this.bus.$on('app/toggle', (d) => this.onToggle(d))
    this.bus.$on('app/redirect', (d) => this.onRedirect(d))

    this.scriptProducts()
  }

  scriptProducts() {
    if ($('.section_script_products').is('*')) {
      $('.section_script_products').each((i, v) => {
        const script = JSON.parse($(v).html())
        this.d.product_groups[script.handle] = script.products
      })
    }
  }

  getDomData() {
    const sectionEls = [...document.querySelectorAll('.background-alt')]
    if (sectionEls.length < 1) return []

    // 1. Construct an array sequence of beginning and end pairs
    // 2. Filter out duplicates so (exactly) adjacent blocks function as one
    return sectionEls
      .reduce((prev, cur) => {
        prev.push(cur.offsetTop, cur.offsetTop + cur.offsetHeight)
        return prev
      }, [])
      .filter((offset, i, self) => self.filter((compare) => compare === offset).length < 2)
  }

  updateDomData() {
    this.domData = this.getDomData()
  }

  setBackground() {
    if (this.domData.length < 2) return

    const scrollTop = this.d.window.s.scroll.top.current
    // Apply this to initial reduce:
    const view = scrollTop + window.innerHeight * 0.5
    const scrolledPast = this.domData.filter((el) => view > el)

    this.s.background_alt = scrolledPast.length % 2 ? true : false
  }

  onToggle(d) {
    //Focus select box
    if (d.key == 'search' && d.toggle == d.key) {
      setTimeout(() => {
        $('#search').focus()
      }, 100)
    }
  }

  onRedirect(d) {
    //Focus select box
    if (d.url) {
      window.location = d.url
    }
  }

  updateJournalPosts(d) {
    const gdh = (el) => el.offsetHeight || el.clientHeight

    const is_columns = this.d.window.s.resize.width.current >= 800
    const journal_posts = document.getElementsByClassName('blocks-journal')
    if (typeof journal_posts[0] != 'undefined') {
      const blocks_wrap = journal_posts[0]
      const blocks = Array.from(blocks_wrap.getElementsByClassName('block'))

      blocks.forEach((block, idx) => {
        if (idx % 2 == 0) {
          const next_block = blocks[idx + 1]
          if (!next_block) return

          const this_title = block.getElementsByClassName('title')[0]
          const this_excerpt = block.getElementsByClassName('excerpt')[0]
          const this_image = block.getElementsByClassName('image')[0]

          const next_title = next_block.getElementsByClassName('title')[0]
          const next_excerpt = next_block.getElementsByClassName('excerpt')[0]
          const next_image = next_block.getElementsByClassName('image')[0]

          // console.log(idx)
          if (is_columns) {
            this_image.style.height = 'auto'
            next_image.style.height = 'auto'

            const highest_title = Math.max(gdh(this_title), gdh(next_title))
            const highest_excerpt = Math.max(gdh(this_excerpt), gdh(next_excerpt))
            const highest_image = Math.max(gdh(this_image), gdh(next_image))

            this_title.style.height = `${highest_title}px`
            this_excerpt.style.height = `${highest_excerpt}px`
            this_image.style.height = `${highest_image}px`

            next_title.style.height = `${highest_title}px`
            next_excerpt.style.height = `${highest_excerpt}px`
            next_image.style.height = `${highest_image}px`
          } else {
            this_title.style.height = 'auto'
            this_excerpt.style.height = 'auto'
            this_image.style.height = 'auto'

            next_title.style.height = 'auto'
            next_excerpt.style.height = 'auto'
            next_image.style.height = 'auto'
          }
        }
      })
    }
  }
}
