export const currentCurrency = window._data.currency

const displayAmount = (amount: number) => {
  if (currentCurrency.iso_code === 'JPY' || currentCurrency.iso_code === 'KRW') {
    return `${amount / 100}`
  }

  return `${(amount / 100).toFixed(2).replace('.00', '')}`
}

export const displayMoney = (amount: number) => {
  return `${currentCurrency.symbol}${displayAmount(amount)} ${currentCurrency.iso_code}`
}

export const displayPrice = ({ price, original }: { price: number; original: number }) => {
  if (original) {
    return `${displayMoney(price)} (was ${displayMoney(original)})`
  }

  return displayMoney(price)
}
