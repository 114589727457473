<template>
  <div class="component-maker">
    <div class="images" @mouseover="onZoom(true)" @mouseleave="onZoom(false)" @click="goto()">
      <div
        v-for="(img, idx) in images"
        :key="`image_${idx}`"
        class="image"
        :style="showImageStyle(img)"
      >
        <img :src="img.src" @mouseover="onPop(img, true)" @mouseleave="onPop(img, false)" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BezierEasing from 'bezier-easing'

export default {
  props: ['image_data', 'link'],
  data: function () {
    return {
      offset: 0,
      images: [],
      zoom: false,
      zoom_rate: 0.05,
    }
  },
  watch: {
    // image_data() {
    //   this.formatImages();
    // }
  },
  mounted() {
    const delay = 500
    if (!this.image_data) {
      setTimeout(
        function request() {
          if (this.image_data) {
            this.formatImages()
            this.tick()
          } else {
            setTimeout(request, delay).bind(this)
          }
        }.bind(this),
        delay
      )
    } else {
      this.formatImages()
      this.tick()
    }
  },
  methods: {
    goto: function () {
      window.location = this.link
    },
    formatImages: function () {
      //Construct array that tracks image x and y
      this.images = _.map(this.image_data, (v, idx) => {
        const radial_spacing = 360 / this.image_data.length
        const radial_direction = radial_spacing * idx + _.random(-10, 10)

        return {
          direction: radial_direction * (Math.PI / 180), //Converted to radians
          min_offset: idx == 0 ? 0 : _.random(10, 15),
          max_offset: idx == 0 ? 0 : _.random(17, 19),
          min_scale: 1,
          max_scale: 1.03,
          src: v,
          z: 100 - idx,
          original_z: 100 - idx,
        }
      })
    },
    onZoom: function (bool) {
      this.zoom = bool
    },
    vec: function (angle, dist) {
      return { x: Math.cos(angle) * dist, y: Math.sin(angle) * dist }
    },
    vecMult: function (p1, val) {
      return { x: p1.x * val, y: p1.y * val }
    },
    vecAdd: function (p1, p2) {
      return { x: p1.x + p2.x, y: p1.y + p2.y }
    },
    showImageStyle: function (img) {
      //Multiply offset by easing function
      const easing = BezierEasing(0.455, 0.03, 0.515, 0.955)
      const offset = easing(this.offset)

      //Calculate distance from center
      const distance = (img.max_offset - img.min_offset) * offset + img.min_offset
      const scale = (img.max_scale - img.min_scale) * offset + img.min_scale

      //Create vector and offset it
      let vector = this.vec(img.direction, distance)
      vector = this.vecAdd(vector, { x: -50, y: -50 })

      return {
        transform: `translateX(${vector.x}%) translateY(${vector.y}%) scale(${scale})`,
        'z-index': img.z,
      }
    },
    tick() {
      if (this.zoom == true && this.offset != 1) {
        this.offset += this.zoom_rate
        this.scale += this.zoom_rate
        if (this.offset > 1) this.offset = 1
      }

      if (this.zoom == false && this.offset != 0) {
        this.offset -= this.zoom_rate
        this.scale += this.zoom_rate
        if (this.offset < 0) this.offset = 0
      }

      window.requestAnimationFrame(() => {
        this.tick()
      })
    },
    onPop(img, active) {
      if (active) {
        img.z = 110
      } else {
        img.z = img.original_z
      }
    },
  },
}
</script>
