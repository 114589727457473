<template>
  <button class="link-currencies a" @click.prevent="onClick">{{ currency }}</button>
</template>

<script lang="ts" setup>
const currency = window._data.currency.iso_code

const onClick = () => {
  window._app.bus.$emit('overlay/open', { overlay: 'currencies' })
}
</script>

<style lang="scss">
.link-currencies {
  color: currentColor;
}
</style>
