import { displayMoney } from '../../lib/money'

export default {
  hasItems: (data) => {
    return data.cart.items && data.cart.items.length
  },
  showItemCount: (data) => {
    return data.cart.item_count
  },
  showItemPrice: (li) => {
    return displayMoney(li.price)
  },
  showItemTotal: (li) => {
    return displayMoney(li.line_price)
  },
}
