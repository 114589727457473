import e from './event'
import m from './mutator'
import s from './state'
import v from './view'

export default class Products {
  constructor(base) {
    this.e = new e(base, this)
    this.m = m
    this.s = s
    this.v = v
  }
}
