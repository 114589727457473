<template>
  <div class="block-products">
    <div class="block-products__inner">
      <BlockProduct
        class="block-products__product"
        v-for="(product, index) in products"
        :product="product"
        :key="`product-${index}`"
      />
    </div>
  </div>
</template>

<script>
import BlockProduct from './block-product.vue'
export default {
  props: {
    products: Array,
  },
  components: { BlockProduct },
}
</script>

<style lang="scss">
@import '~styles/base';

.block-products {
  text-align: center;
  @include offsetX(24, 1);
  @include offsetX(24, 1, right);

  &__inner {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  @include respond-to(m) {
    margin: 0;
    &__inner {
      justify-content: space-between;
    }
  }
}
</style>
