export default class featureDefault {
  constructor(main, feature, is_component = false) {
    //Copy of main class
    this.b = main

    //Inherited from parent
    this.bus = main.bus
    this.ls = main.ls
    this.d = main.d
    this.u = main.u

    if (!is_component) {
      //Feature internals
      this.s = feature.s
      this.v = feature.v
      this.m = feature.m
    } else {
      this.f = feature
      this.s = this.f.s
      this.v = this.f.v
      this.m = this.f.m

      //Set some defaults
      this._default = {} //this.getVueDefaults(this);

      //By default load template from t import
      this._default.template = this.f.t

      this._default.data = () => this.data()

      //Default methods
      this._default.methods = {
        emit: (path, data = {}) => {
          this.bus.$emit(path, data)
        },
      }
    }

    this.init()
  }

  data() {
    //Returns eg e:e,v:v,m:m
    return {
      s: this.f.s,
      v: this.f.v,
      m: this.f.m,
      bus: this.bus,
      d: this.d,
    }
  }

  init() {}
}
