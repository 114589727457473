import './theme.scss'

import _ from 'lodash'
import Vue from 'vue'
import jquery from 'jquery'

import Utils from '../utils'
import store from '../store'

import WindowFeature from '../feat/window'
import AppFeature from '../feat/app'
import LoginFeature from '../feat/login'
import CartFeature from '../feat/cart'
import HomeFeature from '../feat/home'
import ProductFeature from '../feat/product'
import ProductsFeature from '../feat/products'
import AccountFeature from '../feat/account'
// import ElementCartFeature from  './feat/element-cart';
import OverlayFeature from '../feat/overlay'
import OverlayCloseFeature from '../feat/overlay-close'
import OverlayMmenuFeature from '../feat/overlay-mmenu'
import OverlayLoginFeature from '../feat/overlay-login'
import OverlayAfterpayFeature from '../feat/overlay-afterpay'
import OverlayBannerFeature from '../feat/overlay-banner'

import BlockProducts from '~components/block-products.vue'
import Slideshow from '~components/slideshow.vue'
import LazyImage from '~components/lazy-image.vue'
import Newsletter from '~components/newsletter.vue'
import Maker from '~components/maker.vue'
import Related from '~components/related.vue'
import Reveal from '~components/reveal.vue'
import ProductBreadcrumbs from '~components/product-breadcrumbs.vue'
import ElementCart from '~components/element-cart.vue'
import SvgClose from '~components/svg-close.vue'
import SvgArrowNewsletter from '~components/svg-arrow-newsletter.vue'
import ProductSlideshow from '~components/product/ProductSlideshow.vue'
import OverlayGallery from '~components/overlay/overlay-gallery.vue'
import OverlayCurrencies from '~components/overlay/overlay-currencies.vue'
import LinkCurrencies from '~components/link/link-currencies.vue'

import { displayMoney } from '../lib/money'

window.Vue = Vue
window._ = _
window.$ = jquery

Vue.component('Slideshow', Slideshow)
Vue.component('LazyImage', LazyImage)
Vue.component('Newsletter', Newsletter)
Vue.component('Maker', Maker)
Vue.component('Related', Related)
Vue.component('Reveal', Reveal)
Vue.component('ProductBreadcrumbs', ProductBreadcrumbs)
Vue.component('ElementCart', ElementCart)
Vue.component('SvgClose', SvgClose)
Vue.component('SvgArrowNewsletter', SvgArrowNewsletter)
Vue.component('BlockProducts', BlockProducts)
Vue.component('ProductSlideshow', ProductSlideshow)
Vue.component('LinkCurrencies', LinkCurrencies)
Vue.component('OverlayGallery', OverlayGallery)
Vue.component('OverlayCurrencies', OverlayCurrencies)

export default class App {
  constructor() {
    this.d = window._data // Data provided on-page via script tags
    this.bus = new Vue()
    this.u = new Utils(this)

    // Create base vue data object
    this.features = {
      window: new WindowFeature(this),
      app: new AppFeature(this),
      login: new LoginFeature(this),
      cart: new CartFeature(this),
      home: new HomeFeature(this),
      product: new ProductFeature(this),
      products: new ProductsFeature(this),
      account: new AccountFeature(this),
      overlay: new OverlayFeature(this),
      'overlay-close': new OverlayCloseFeature(this),
      'overlay-login': new OverlayLoginFeature(this),
      'overlay-afterpay': new OverlayAfterpayFeature(this),
      'overlay-banner': new OverlayBannerFeature(this),
      overlaymmenu: new OverlayMmenuFeature(this),
      // "element-cart": new ElementCartFeature(this),
    }

    // Assign features to the vue data object
    // _.each((new Feat(this).c), (c,k) => {this.f[k] = c;});

    // Create Vue instance
    this.vm = new Vue({
      data: () => ({
        d: this.d,
        ls: this.ls,
        bus: this.bus,
        loaded: false,
        ...this.features,
      }),
      methods: {
        displayMoney(amount) {
          return displayMoney(amount)
        },
        // goto(url) {
        //   window.location = url
        // },
        // log(log) {
        //   console.log(log)
        // },
        emit: (path, data = {}) => {
          this.bus.$emit(path, data)
        },
      },
      store,
    })

    this.vm.$mount('#app')
    this.bus.$emit('vue/loaded', { vue: this.vm })
    this.vm.loaded = true
  }
}

window._app = new App()
