import $ from 'jquery'

export default class Default {
  constructor(main, shopify) {
    this.u = main.utils
    this.s = shopify

    //Default ajax settings
    this._defaults = shopify._ajax_defaults
  }

  get(opts) {
    const url = '/cart?view=json'
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: JSON.stringify(data),
      type: 'GET',
      dataType: 'html',
      success: (d) => {
        d = JSON.parse(d)
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }

  add(opts) {
    const url = '/cart/add.js'
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: data,
      contentType: 'application/x-www-form-urlencoded',
      type: 'POST',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }

  update(opts) {
    const url = '/cart/update.js'
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: data,
      contentType: 'application/x-www-form-urlencoded',
      type: 'POST',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }

  clear(opts) {
    const url = '/cart/clear.js'
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: JSON.stringify(data),
      type: 'POST',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }
}
