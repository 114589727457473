import _ from 'lodash'
// import $ from 'jquery';
import Vue from 'vue'

import Base from '../base'
import Event from './event'

export default class Default extends Base {
  init() {
    const main = this.b
    Vue.component(
      'OverlayBanner',
      _.merge(this._default, {
        props: ['overlay', 'text'],
        mounted: function () {
          new Event(this, main)
        },
      })
    )
  }
}
