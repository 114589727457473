<template>
  <img :src="getSrc" :class="{ active: loaded }" />
</template>

<script>
import { resizeImage } from '../lib/image'
import BrowserMixin from '../mixins/browser'

export default {
  mixins: [BrowserMixin],
  props: {
    src: String,
    size: String,
  },
  data() {
    return {
      ready: false,
      loaded: false,
    }
  },
  computed: {
    getSrc() {
      if (!this.ready) return false
      if (this.loaded) return resizeImage(this.src, this.size)
      return false
    },
  },
  watch: {
    scrollTop: {
      handler: 'checkIfLoaded',
      immediate: true,
    },
    resizeHeight: {
      handler: 'checkIfLoaded',
      immediate: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.ready = true
    },
    checkIfLoaded() {
      const top = this?.$el?.offsetTop || 0
      const ahead = this.scrollTop + 2 * this.resizeHeight
      const behind = this.scrollTop - 2 * this.resizeHeight

      if (top < ahead && top > behind) {
        this.loaded = true
      }
    },
  },
}
</script>
