import s from './state'
import m from './mutator'
import v from './view'
import t from './template'
import C from './component'

export default class OverlayBanner {
  constructor(main) {
    this.s = s
    this.v = v
    this.m = m
    this.t = t

    new C(main, this, true)
  }
}
