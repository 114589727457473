import _ from 'lodash'
// import $ from 'jquery';
// import Vue from 'vue';

import Base from '../base'

export default class Default extends Base {
  init() {
    if (this.d.template !== 'index ') return
    this.bus.$on('vue/loaded', () => {
      this.loadData()
    })
    // this.bus.$on('vue/loaded', (d) => {this.landing();});
    // this.bus.$on('vue/loaded', (d) => {this.landingInterval();});
  }

  loadData() {
    // console.log(document.querySelector('.block_data'))
    const blockData = document.querySelector('.block_data')
    if (!blockData) return
    this.s.data = JSON.parse(blockData.innerHTML).reduce(
      (acc, block) => ((acc[block.id] = block), acc),
      {}
    )
  }

  landing() {
    //
    this.s.category_images = this.d.category_images

    //Preload images
    const image_urls = _.map(this.d.category_images, 'image')
    this.u.preload.files({ src: image_urls })
  }

  landingInterval() {
    const images = this.d.landing_images
    const left = images.left.length,
      right = images.right.length
    const limit = (left < right ? left : right) - 1
    const state = this.s

    const delay = 6500
    setTimeout(function interval() {
      if (state.current_landing >= limit) {
        state.current_landing = 0
      } else {
        state.current_landing++
      }

      setTimeout(interval, delay)
    }, delay)
  }
}
