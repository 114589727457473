import $ from 'jquery'

export default class Default {
  constructor(main, shopify) {
    this.u = main.utils
    this.s = shopify

    //Default ajax settings
    this._defaults = shopify._ajax_defaults
  }

  getAll(opts) {
    const url = `/admin/products.json`
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: JSON.stringify(data),
      method: 'GET',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }

  search(opts) {
    const url = '/search?view=json'
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: data,
      method: 'GET',
      dataType: 'json',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }

  collection(opts) {
    const url = `/collections/${opts.handle}`
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, { view: 'json' }, opts.data)

    $.extend(true, settings, {
      url: url,
      data: data,
      method: 'GET',
      dataType: 'html',
      success: (d) => {
        const json = JSON.parse(d)
        if (typeof opts.callback == 'function') opts.callback(json)
      },
    })

    //Call url
    $.ajax(settings)
  }

  getByHandle(opts) {
    console.log(opts)
    const url = `/products/${opts.handle}.js`
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: JSON.stringify(data),
      method: 'GET',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }

  getByID(opts) {
    const url = `/admin/products/${opts.id}.json`
    const settings = $.extend({}, this._defaults)
    const data = $.extend(true, {}, opts.data)

    $.extend(true, settings, {
      url: url,
      data: JSON.stringify(data),
      method: 'GET',
      success: (d) => {
        if (typeof opts.callback == 'function') opts.callback(d)
      },
    })

    //Call url
    $.ajax(settings)
  }
}
