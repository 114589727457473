<template>
  <div class="block-product">
    <Reveal>
      <div class="block-inner">
        <a :href="product.url" class="link"></a>
        <div class="image">
          <div class="mood" :style="showMood"></div>
          <LazyImage :src="product.featured_image.src" :size="size" />
        </div>
        <div class="caption">
          <h3 class="vendor">{{ product.vendor }}</h3>
          <h2>
            <span class="title">{{ product.title }}</span
            >,
            <ProductPrice :product="product" class="price" />
          </h2>
        </div>
      </div>
    </Reveal>
  </div>
</template>

<script>
import Reveal from './reveal.vue'
import LazyImage from './lazy-image.vue'
import ProductPrice from './product-price.vue'
import { resizeImage } from '../lib/image'

import { PRODUCT_IMAGE_SIZE } from '../lib/constants'

export default {
  props: {
    product: Object,
  },
  computed: {
    showMood() {
      const imagePath =
        this.product.images.length > 1 ? this.product.images[1].src : this.product.images[0].src

      const resizePath = resizeImage(imagePath, PRODUCT_IMAGE_SIZE)

      return { 'background-image': `url(${resizePath}` }
    },
    size() {
      return PRODUCT_IMAGE_SIZE
    },
  },
  components: { Reveal, LazyImage, ProductPrice },
}
</script>

<style lang="scss">
@import '~styles/base';

.block-product {
  @include inlineX(22, 6);
  margin-bottom: 100px;
  position: relative;
  .link {
    @include cover-link();
    z-index: 10;
  }
  .image {
    transition: opacity 0.2s ease;
  }
  .caption {
    @include cx(m);
    padding-top: 30px;
    min-height: 115px;
    text-align: center;
    .vendor {
      @include cx(s1);
      @include cx-uc();
      padding-bottom: 5px;
    }
    .price {
      .original {
        text-decoration: line-through;
      }
    }
  }
  &:nth-child(3n + 2) {
    @include offsetX(22, 2);
    @include offsetX(22, 2, right);
  }
  &:hover {
    .image {
      opacity: 0.8;
    }
  }

  @include respond-to(m) {
    width: calc(50% - #{$inset / 2});
    margin-bottom: 40px;
    &:nth-child(3n + 2) {
      margin-right: 0;
      margin-left: 0;
    }
  }
  @include respond-to(xxxs) {
    width: calc(100% - #{$inset / 2});
  }
}
</style>
