// Helpers
// import Checkout from './helpers/checkout'
import Shopify from './shopify'

//External
// import ccValidator from '../../../node_modules/card-validator';

export default class Default {
  constructor(main) {
    //Shopify
    this.shopify = new Shopify(main)

    //Helpers
    // this.helpers = new Helpers(main)
    // this.preload = new Preload(main)
    // this.checkout = new Checkout(main)

    //External
    // this.ccV = ccValidator;
  }
}
