<template>
  <img class="shopify-image" :src="url" ref="el" />
</template>

<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'
import { ref, computed } from 'vue'
import { getShopifyImage, ratioAsNumber } from '../../lib/image'

const props = withDefaults(
  defineProps<{
    src: string
    ratio?: string
  }>(),
  {
    ratio: '1x1',
  }
)

const el = ref(null)
const { width: elWidth } = useElementSize(el)
const ratioNumber = ratioAsNumber(props.ratio)

const pixelDensity = window.devicePixelRatio || 2

const size = computed(() => {
  if (elWidth.value == 0) {
    const blurWidth = 600
    return {
      width: blurWidth * pixelDensity,
      height: ratioNumber * blurWidth * pixelDensity,
    }
  } else {
    return {
      width: elWidth.value * pixelDensity,
      height: ratioNumber * elWidth.value * pixelDensity,
    }
  }
})

const url = computed(() => {
  const { url: src } = getShopifyImage(props.src, {
    modifiers: {
      width: size.value.width,
      ratio: props.ratio,
    },
  })
  return src
})
</script>

<style lang="scss"></style>
