<template>
  <div class="product-price">
    <span v-html="getPrice.price" />
    <span v-if="getPrice.message">, </span>
    <span v-if="getPrice.message" class="message">(<span v-html="getPrice.message" />) </span>
  </div>
</template>

<script>
import _ from 'lodash'
import { displayPrice } from '~/lib'

export default {
  props: {
    product: Object,
  },
  computed: {
    getPrice() {
      let message = false

      const prices = this.product.variants.map((v) => v.price)

      // Conditionals
      const hasVariants = this.product.variants.length > 1
      const uniquePrices = _.uniq(prices).length > 1

      let price
      if (hasVariants && uniquePrices) {
        //Get variant with lowest price
        const variant = this.product.variants.reduce((acc, v, k) => {
          if (!acc) {
            acc = v
          } else {
            if (v.price < acc.price) {
              acc = v
            }
          }

          return acc
        }, false)

        price =
          'From&nbsp;' +
          displayPrice({
            price: variant.price,
            original: variant.compare_at_price,
          })
      } else {
        price = displayPrice({
          price: this.product.price,
          original: this.product.compare_at_price,
        })
      }

      if (!this.product.available) message = 'Sold Out'

      return {
        message,
        price,
      }
    },
  },
}
</script>
